<template>
  <div class="star_page">
    <img src="@/assets/images/star/loading.gif" alt="" />
  </div>
</template>

<script>
import loadingGif from '../../assets/images/star/loading.gif'
export default {
  name: 'talent',

  data: () => {
    return { loadingGif: loadingGif }
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.star_page{
  text-align: center;
  img{
    width: 1788px;
    margin: 60px auto;
    display: block;
  }
}
</style>
